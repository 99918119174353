import React, {Fragment} from 'react';
import {Row} from 'jsxstyle';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';
import {Location} from '@reach/router';

import Layout from '@layouts';
import Section from '@components/layout/Section';
import SectionTitle from '@components/SectionTitle';
import Hero from '@components/Hero';
import PreFooter from '@components/PreFooter';
import {BLUE, ORANGE, WHITE} from '@styles/colors';
import ImageCallout from '@components/ImageCallout';
import Instagram from '@components/icons/Instagram';
import Button from '@components/Button';
import Link from '@components/Link';
import {stripTagsFromWagtail} from '@util';

import {SCHEMA_MAIN} from '@util/googleSchema';

const GENERIC_CONFIRMATION_MESSAGE =
  'Got it! A Fetner team member will be in touch shortly.';

export default ({data}: Object) => {
  const {page} = data.wagtail;
  const {
    seoTitle,
    searchDescription,
    thanksEyebrow,
    thanksHeadline,
    thanksCopy,
    calloutHeadline,
    calloutCopy,
    calloutImage,
    calloutCtaTextA,
    calloutCtaLinkA,
    calloutCtaTextB,
    calloutCtaLinkB,
  } = page;
  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{seoTitle}</title>
        <meta name="description" content={searchDescription} />
        <script type="application/ld+json">
          {JSON.stringify(SCHEMA_MAIN)}
        </script>
      </Helmet>
      <Layout menuItemColor={BLUE} menuBackground={WHITE}>
        <Location>
          {({location}) => {
            const searchParam = Boolean(location.search);
            return (
              <Fragment>
                <Hero background={WHITE}>
                  <SectionTitle
                    eyebrow={thanksEyebrow}
                    headline={thanksHeadline}
                    copy={
                      searchParam
                        ? GENERIC_CONFIRMATION_MESSAGE
                        : thanksCopy && stripTagsFromWagtail(thanksCopy)
                    }
                    dividerColor={ORANGE}
                    alignItems="center"
                    textAlign="center"
                    copyColor={BLUE}
                    eyebrowColor={ORANGE}
                  />
                </Hero>
                <Section>
                  <ImageCallout
                    headline={calloutHeadline}
                    copy={calloutCopy && stripTagsFromWagtail(calloutCopy)}
                    imageSrc={calloutImage && calloutImage.url}
                    textAlign="left"
                    imgMaxHeight="600px"
                  >
                    <Row marginBottom={40} alignItems="center">
                      <Instagram fillColor={BLUE} />
                      <Link
                        href="https://www.instagram.com/fetnernyc/"
                        style={{
                          textTransform: 'capitalize',
                          textDecoration: 'none',
                          height: 'auto',
                          color: BLUE,
                          marginLeft: '10px',
                        }}
                      >
                        follow us @FetnerNYC
                      </Link>
                    </Row>
                    <Row>
                      <Button
                        buttonText={calloutCtaTextA}
                        buttonLink={calloutCtaLinkA}
                        buttonColor={ORANGE}
                        margin="0 20px 0 0"
                      />
                      <Button
                        buttonText={calloutCtaTextB}
                        buttonLink={calloutCtaLinkB}
                        buttonColor={BLUE}
                      />
                    </Row>
                  </ImageCallout>
                </Section>
                <PreFooter
                  headline="Interested in becoming a resident?"
                  copy="Get in touch with us to find your perfect Fetner apartment."
                />
              </Fragment>
            );
          }}
        </Location>
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  fragment ConfirmationFragment on Wagtail_ContactPage {
    seoTitle
    searchDescription
    landingEyebrow
    landingHeadline
    landingCopy
    thanksEyebrow
    thanksHeadline
    thanksCopy
    calloutHeadline
    calloutCopy
    calloutImage {
      url
    }
    calloutCtaTextA
    calloutCtaLinkA
    calloutCtaTextB
    calloutCtaLinkB
  }
  query {
    wagtail {
      page(slug: "contact") {
        ...ConfirmationFragment
      }
    }
  }
`;
